import AVATAR_LOGO from "assets/images/user-Avata.png";
import Text from "components/general/Text";
import Image from "components/general/Image";
import { AssigneeCardProps } from "./types";
import styles from "./styles.module.scss";

export default function AssigneeCard({
  containerStyle,
  data,
}: AssigneeCardProps) {
  console.log(data?.cfm_id, "cfm_id");
  console.log(data?.id);

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <div className={styles.memberImage}>
        <Image src={AVATAR_LOGO} alt="arrow" />
      </div>

      <div className={styles.memberContent}>
        <Text style={{ fontSize: "14px", fontWeight: "600" }}>
          {`${data?.user?.first_name} ${data?.user?.last_name}`}
        </Text>
        <div>
          <Text style={{ fontSize: "12px" }}>{data?.job_title} -</Text>
          <Text style={{ fontSize: "12px" }}>ID: {data?.cfm_id}</Text>
        </div>
      </div>
    </div>
  );
}
