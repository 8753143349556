import api from "apis";
import { User } from "apis/types/auth";
import { PaginatedResponse, PaginationParams } from "apis/types/general";
import {
  AllStaffParams,
  AllStaffType,
  MyStaffProp,
  NewStaffBodyType,
} from "apis/types/staff";
import getSerializedQueryArgs from "utils/getSerializedQueryArgs";

export const staffApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyStaff: build.query<MyStaffProp, PaginationParams>({
      query: (params) => ({
        url: `/auth/my-staff/`,
        params: {
          ordering: "-id",
          ...Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v !== "")
          ),
        },
      }),
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),
    getMyStaffDropdown: build.query<MyStaffProp, PaginationParams>({
      query: (params) => ({
        url: `/auth/my-staff/staff_drop_down/`,
        params: {
          ordering: "-id",
          ...Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v !== "")
          ),
        },
      }),
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    getMyStaffCheckins: build.query({
      query: (params) => ({
        url: `/my_staff_checkins/`,
        params,
      }),
    }),

    getStaffMemberInfo: build.query<User, string>({
      query: (id) => ({
        url: `/auth/my-staff/${id}/`,
      }),
    }),

    addNewStaff: build.mutation<void, NewStaffBodyType>({
      query: (body) => ({
        url: "/auth/staff/all/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["AllStaff"],
    }),

    editStaff: build.mutation<void, Partial<NewStaffBodyType>>({
      query: (body) => ({
        url: `/auth/staff/all/${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, err, arg) => [
        "AllStaff",
        { type: "AllStaff", id: arg.id },
      ],
    }),

    getAllStaff: build.query<PaginatedResponse<AllStaffType>, AllStaffParams>({
      query: (params) => ({
        url: `/auth/staff/all/`,
        params: { ordering: "-id", ...params },
      }),
      serializeQueryArgs: getSerializedQueryArgs,
      providesTags: ["AllStaff"],
    }),

    getSpecificStaffFromAllStaff: build.query<AllStaffType, number>({
      query: (id) => ({
        url: `/auth/staff/all/${id}/`,
      }),
      providesTags: (result, err, arg) => [{ type: "AllStaff", id: arg }],
    }),
    getBalanceDetailsForUser: build.query<any, any>({
      query: (params) => ({
        url: `/staff-balance/`,
        params,
      }),
      providesTags: ["userBalanceStaff"],
    }),
    updateUserBalanceUpdate: build.mutation<any, any>({
      query: (body) => ({
        url: `/staff-balance/${body?.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["userBalanceStaff"],
    }),

    deleteStaff: build.mutation<void, number>({
      query: (id) => ({
        url: `/auth/staff/all/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["AllStaff"],
    }),
    getNotifications: build.query<any, any>({
      query: () => ({
        url: `/notification/`,
        params: { ordering: "-id", page_size: 50 },
      }),
    }),
  }),
});

export const {
  useGetMyStaffQuery,
  useGetMyStaffDropdownQuery,
  useLazyGetMyStaffDropdownQuery,
  useUpdateUserBalanceUpdateMutation,
  useLazyGetMyStaffQuery,
  useGetMyStaffCheckinsQuery,
  useGetStaffMemberInfoQuery,
  useAddNewStaffMutation,
  useEditStaffMutation,
  useGetAllStaffQuery,
  useGetSpecificStaffFromAllStaffQuery,
  useLazyGetSpecificStaffFromAllStaffQuery,
  useGetBalanceDetailsForUserQuery,
  useLazyGetAllStaffQuery,
  useDeleteStaffMutation,
  useGetNotificationsQuery,
} = staffApi;
