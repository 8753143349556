import CustomSteps from "components/general/CustomSteps";
import { useState } from "react";
import UploadFile from "components/general/UploadFile";
import { useBulkUploadMutation } from "apis/services/bulkUpload";
import ModalWrapper from "../ModalWrapper";
import { FileUploadProps } from "./types";
import styles from "./styles.module.scss";

export default function UploadFiles({
  isVisible,
  setIsVisible,
  page,
}: FileUploadProps) {
  const [bulkUpload] = useBulkUploadMutation();

  const handleCustomButtonClick = () => {
    if (page === "shifts1" || page === "shifts2" || page === "shifts3") {
      const tempFile = [
        "https://gazef-bak.s3.amazonaws.com/cfm_attendance/media/None/templates/fixed_shift.xls",
        "https://gazef-bak.s3.amazonaws.com/cfm_attendance/media/None/templates/based_shift.xls",
        "https://gazef-bak.s3.amazonaws.com/cfm_attendance/media/None/templates/flexible_shifts.xls",
      ];

      const localFilePath = tempFile[Number(page.charAt(page.length - 1)) - 1];
      const fileName = "template.xls";

      const link = document.createElement("a");
      link.href = localFilePath;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const [currentStep, setCurrentStep] = useState(0);
  const [fileList, setFileList] = useState<any[]>([]);

  const shifts_steps = [
    {
      title: "Download Staff Data",
      content: "",
      buttonText: "Download ( 25 Staff ) ",
      modalTitle: "Bulk Upload Staff",
    },
    {
      title: "Upload New request",
      content: (
        <UploadFile
          fileList={fileList}
          setFileList={(x: any) => setFileList(x)}
        />
      ),
      buttonText: "Download file Template",
      modalTitle: "Bulk Upload Shift",
    },
  ];
  const requests_steps = [
    {
      title: "Download Requests Data",
      content: "",
      buttonText: "Download ( 25 Staff ) ",
      modalTitle: "Bulk Upload Staff",
    },
    {
      title: "Upload New request",
      content: (
        <UploadFile
          fileList={fileList}
          setFileList={(x: any) => setFileList(x)}
        />
      ),
      buttonText: "Download file Template",
      modalTitle: "Bulk Upload Request",
    },
  ];
  const steps = page === "requests" ? requests_steps : shifts_steps;

  const handleStepChange = (step: number) => {
    setCurrentStep(step);
  };

  const handleClose = () => {
    /* 
// @ts-ignore */
    setIsVisible(false);
    setFileList([]);
  };

  const onUploadFile = (cb: any) => {
    const typesMap: { [key: string]: string } = {
      shifts1: "fixed shifts",
      shifts2: "based shifts",
      shifts3: "flexible shifts",
    };

    if (!page) return;
    if (!typesMap[page]) return;

    const formData = new FormData();
    formData.append("sheet", fileList[0]);
    formData.append("type", typesMap[page]);

    bulkUpload(formData)
      .unwrap()
      .then(() => {
        cb?.();
      });
  };

  return (
    <ModalWrapper
      size="large"
      headerTitle={steps[currentStep].modalTitle}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isFooterHidden
    >
      <div className={styles.container}>
        <CustomSteps
          steps={steps}
          filesLength={fileList.length}
          customButtonClick={handleCustomButtonClick}
          handleClose={handleClose}
          /* 
// @ts-ignore */
          onStepChange={(step) => handleStepChange?.(step)}
          onUploadHandler={onUploadFile}
        />
      </div>
    </ModalWrapper>
  );
}
