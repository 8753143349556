/* eslint-disable */
import { useState } from "react";
import {
  useDeleteFingerprintsMutation,
  useGetFingerprintsQuery,
} from "apis/services/locations";

import ImportImage from "assets/icons/import.svg";
import DownloadImage from "assets/icons/download.svg";

import Table from "components/general/Table";
import { useSearchParams } from "react-router-dom";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import Text from "components/general/Text";
import MenuTableDataWrapper from "components/general/MenuTableDataWrapper";
import { MenuDataItem } from "components/general/MenuTableDataWrapper/types";
import showSuccessMsg from "utils/showSuccessMsg";
import { Tooltip } from "antd";
import IMPORTANT_VARS from "constants/ImportantVars";
import moment from "moment";
import styles from "./styles.module.scss";
import AddNewFingerprintModal from "components/modals/AddNewFingerprint";
import Button from "components/general/Button";
import Image from "components/general/Image";

export default function FingerPrints() {
  const [searchParams] = useSearchParams();

  const [isAddNewFingerModalVisible, setisAddNewFingerModalVisible] =
    useState(false);
  const [isEditFingerPrint, setisEditFingerPrint] = useState(false);
  const shouldShowModal = isAddNewFingerModalVisible || isEditFingerPrint;
  const [selectedFinger, setselectedFinger] = useState({});

  const { data: locations, isFetching: isFingerprintsLoading } =
    useGetFingerprintsQuery({
      ordering: "-id",
      page: searchParams.get("page") ?? 1,
      page_size: IMPORTANT_VARS.TabelDataPageSize,
    });

  const [deleteFingerPrint, { isLoading: isDeletingLocationLoading }] =
    useDeleteFingerprintsMutation();

  const onGetDeleteLocationFunction = (fingerID: number) => () => {
    deleteFingerPrint(fingerID)
      .unwrap()
      .then(() => {
        showSuccessMsg({
          msg: "This fingerprint has been deleted successfully!",
        });
      });
  };
  const onEditFunction = (fingerPrint: any) => () => {
    setisEditFingerPrint(true);
    setselectedFinger(fingerPrint);
  };

  const getLocationMenuData = (fingerPrint: any): MenuDataItem[] => [
    {
      key: "edit",
      label: "Edit",
      isDanger: false,
      isLoading: false,
      actionFn: onEditFunction(fingerPrint),
    },
    {
      key: "delete",
      label: "Delete",
      isDanger: true,
      isLoading: isDeletingLocationLoading,
      actionFn: onGetDeleteLocationFunction(fingerPrint?.id),
    },
  ];

  const tabelData =
    locations?.results?.map((item: any) => ({
      id: item.id,
      rowData: [
        {
          dataItem: item.device_number,
          type: "text",
        },
        {
          dataItem: item.location.address,
          type: "text",
        },
        {
          dataItem: item.active ? "Connecting" : "Offline",
          type: "text",
        },
        {
          dataItem: (
            <MenuTableDataWrapper menuData={getLocationMenuData(item)}>
              <Tooltip title={item.description} id={item.id}>
                <div className="pointer">
                  <Text className={styles.description}>{item.description}</Text>
                </div>
              </Tooltip>
            </MenuTableDataWrapper>
          ),
          type: "element",
        },
      ],
    })) || [];

  const onShowAddModal = () => {
    setisAddNewFingerModalVisible(true);
  };
  const onCloseModal = () => {
    setisAddNewFingerModalVisible(false);
    setisEditFingerPrint(false);
  };

  const tabelHeaders = ["Device numbers", "Location", "Account Status"];

  const lastUpdateDate = moment().format("DD/M/YYYY - hh:mm a");

  const SubtitleMarkup = (
    <Text className={styles.subtitle}>
      Last Update:
      <Text className={styles.bold}>{lastUpdateDate}</Text>
    </Text>
  );

  const RightComponent = (
    <div className="d-flex flex-row">
      <Button
        btnClassName={`px-5 ${styles.headerBtn}`}
        isOutline
        onClick={() => setisAddNewFingerModalVisible(true)}
      >
        +
      </Button>

      <Button isOutline btnClassName={`mx-2 ${styles.headerBtn}`}>
        <Image src={DownloadImage} />
      </Button>
      <Button isOutline btnClassName={styles.headerBtn}>
        <Image src={ImportImage} />
      </Button>
    </div>
  );
  return (
    <div>
      <SecondaryPageHeader
        title="Fingerprint Devices"
        SubTitleComponent={SubtitleMarkup}
        RightComponent={RightComponent}
      />

      <div>
        <Table
          containerStyle={styles.locationContainer}
          showSearchFilter={false}
          headers={tabelHeaders}
          isDataCentered
          isHeaderCentered
          isLoading={isFingerprintsLoading}
          data={tabelData}
          // isCheckBoxVisible={false}
          count={locations?.count}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>
      {shouldShowModal && (
        <AddNewFingerprintModal
          isVisible={shouldShowModal}
          setIsVisible={onCloseModal}
          isUpdate={isEditFingerPrint}
          initialValues={selectedFinger}
        />
      )}
    </div>
  );
}
