/* eslint-disable */
import { Col, Row } from "antd";
import COLORS from "constants/Colors";
import styles from "./styles.module.scss";
import { InfoDetailsCardProps } from "./types";
import Paper from "../PaperComponent";

export default function InfoDetailsCard({
  cardTitle,
  data,
  topActionButtons,
  children,
}: InfoDetailsCardProps) {
  return (
    <Paper borderColor="transparent" backgroundColor={COLORS.light}>
      <div className={styles.cardTitle}>
        <span style={{ marginBlock: "auto" }}>{cardTitle}</span>
        {topActionButtons}
      </div>
      {!!data ? (
        <div>
          {data?.map((item) => (
            <Row className={styles.dataContainer}>
              {item.label !== "" && (
                <Col lg={3} className={styles.label}>
                  {item.label}
                </Col>
              )}
              <Col className={styles.valu}>{item.value}</Col>
            </Row>
          ))}
        </div>
      ) : (
        children
      )}
    </Paper>
  );
}
