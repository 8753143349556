import infintyPaginationMergeHandler from "utils/infintyPaginationMergeHandler";
import getSerializedQueryArgs from "utils/getSerializedQueryArgs";
import api from "apis";

export const locationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDepartments: build.query({
      query: (params) => ({
        url: "/locations/departments/",
        params,
      }),
      providesTags: ["Department"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      merge: infintyPaginationMergeHandler,
    }),

    getLocations: build.query({
      query: (params) => ({
        url: "/locations/locations/",
        params,
      }),
      providesTags: ["Location"],
      serializeQueryArgs: getSerializedQueryArgs,
    }),

    getZones: build.query({
      query: (params) => ({
        url: "/locations/zones/",
        params,
      }),
      providesTags: ["Zone"],
      serializeQueryArgs: getSerializedQueryArgs,
      merge: infintyPaginationMergeHandler,
    }),

    getFingerprintDevices: build.query({
      query: (params) => ({
        url: "/locations/fingerprint_devices/",
        params,
      }),
      providesTags: ["fingerprint_devices"],
      serializeQueryArgs: getSerializedQueryArgs,
      merge: infintyPaginationMergeHandler,
    }),

    getProjects: build.query({
      query: (params) => ({
        url: "/locations/projects/",
        params,
      }),
      providesTags: ["Location"],
    }),

    addLocation: build.mutation({
      query: (body) => ({
        url: "/locations/locations/",
        body,
        method: "POST",
      }),
      invalidatesTags: ["Location"],
    }),
    editLocation: build.mutation({
      query: (body) => ({
        url: `/locations/locations/${body.id}/`,
        body,
        method: "PUT",
      }),
      invalidatesTags: ["Location"],
    }),
    deleteLocation: build.mutation({
      query: (id) => ({
        url: `/locations/locations/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Location"],
    }),

    getCategories: build.query({
      query: (params) => ({
        url: "/locations/categories/",
        params,
      }),
      providesTags: ["Category"],
      serializeQueryArgs: getSerializedQueryArgs,
    }),

    getLocation: build.query({
      query: ({ id, params }) => ({
        url: `/locations/locations/${id}`,
        params,
      }),
      providesTags: ["Location"],
    }),
    getFingerprints: build.query({
      query: (params) => ({
        url: `/fingerprint_devices`,
        params,
      }),
      providesTags: ["fingerprint"],
    }),
    addFingerprint: build.mutation({
      query: (body) => ({
        url: `/fingerprint_devices/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["fingerprint"],
    }),
    editFingerprint: build.mutation({
      query: (body) => ({
        url: `/fingerprint_devices/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["fingerprint"],
    }),
    deleteFingerprints: build.mutation({
      query: (id) => ({
        url: `/fingerprint_devices/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["fingerprint"],
    }),
  }),
});

export const {
  useGetDepartmentsQuery,
  useGetFingerprintDevicesQuery,
  useGetLocationsQuery,
  useGetZonesQuery,
  useGetProjectsQuery,
  useAddLocationMutation,
  useEditLocationMutation,
  useDeleteLocationMutation,
  useLazyGetLocationsQuery,
  useGetCategoriesQuery,
  useGetLocationQuery,
  useLazyGetLocationQuery,
  // fingerPrints
  useGetFingerprintsQuery,
  useAddFingerprintMutation,
  useEditFingerprintMutation,
  useDeleteFingerprintsMutation,
} = locationApi;
