import AsyncSelect from "react-select/async";
import { components, MultiValueRemoveProps } from "react-select";
import { CloseOutlined } from "@ant-design/icons";
import { useLazyGetMyStaffDropdownQuery } from "apis/services/staff";
import AssigneeCard from "components/cards/AssigneeCard";
// import { AssigneeSelectionProps } from "./types";

function MultiValueRemove(props: MultiValueRemoveProps<any>) {
  return (
    <components.MultiValueRemove {...props}>
      <CloseOutlined
        style={{
          color: "#898989",
          fontSize: 20,
          paddingInline: 20,
        }}
      />
    </components.MultiValueRemove>
  );
}

function MultiValueLabel(value: { data: any }) {
  return <AssigneeCard data={value?.data?.data} />;
}

export default function AssigneeSelection({
  isMulti = true,
  value,
  onChange,
}: any) {
  const [getAllStaff] = useLazyGetMyStaffDropdownQuery();

  const promiseOptions = (inputValue: string, callback: (v: any) => void) => {
    getAllStaff({ search: inputValue, page_size: 10, employment_type: 1 })
      .then(({ data }) => {
        const options = data?.results.map((item) => ({
          label: `${item?.user?.first_name} ${item?.user?.last_name}`,
          value: item.id,
          data: item,
        }));
        callback(options);
      })
      .catch(() => callback([]));
  };

  return (
    <AsyncSelect
      isMulti={isMulti}
      cacheOptions
      defaultOptions
      isClearable={false}
      value={value}
      onChange={onChange}
      loadOptions={promiseOptions}
      components={{
        MultiValueLabel,
        MultiValueRemove,
        DropdownIndicator: () => null,
      }}
      styles={{
        multiValue: (base) => ({
          ...base,
          border: "1px solid #d9d9d9",
          borderRadius: 24,
          backgroundColor: "white",
          overflow: "hidden",
          marginBlock: 5,
        }),
        control: (base) => ({
          ...base,
          border: "1px solid #d9d9d9",
          borderRadius: 12,
        }),
      }}
    />
  );
}
