import { useState } from "react";

export default function useSearchableSelection(
  fetchData: (params: any) => any,
  options: Record<string, any> = {}
) {
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string | undefined>();

  const { data } = fetchData({
    page_size: 100,
    search: searchValue,
    ...options,
  });

  const handleSearch = (value: string) => setSearchValue(value);
  const handleChange = (value: string) => setSelectedValue(value);

  return {
    data,
    selectedValue,
    handleSearch,
    handleChange,
  };
}
