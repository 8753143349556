import { useState } from "react";
import Button from "components/general/Button";
import Text from "components/general/Text";
import StatusBadge from "components/general/StatusBadge";
import ManageRequest from "components/modals/ManageRequest";
import { RequestCardProps } from "./types";
import styles from "./styles.module.scss";

export default function RequestCard({
  data,
  containerStyle,
  isMyRequest,
}: RequestCardProps) {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <Button
        btnClassName={`${styles.container} ${containerStyle}`}
        onClick={() => setIsModalShown(true)}
      >
        <div className={styles.content}>
          <Text>Request ID: {data.id}</Text>
          <Text>{data?.request_type}</Text>
          <Text>{data?.date ?? data?.start_date}</Text>
          <Text>{data?.reason}</Text>
        </div>
        <StatusBadge
          color={data?.status === "accepted" ? "success" : "warning"}
          text={data?.status}
          containerStyle={styles.statusBadge}
        />
      </Button>
      {isModalShown && (
        <ManageRequest
          request={data}
          setIsVisible={setIsModalShown}
          isVisible={isModalShown}
          is_my_request={isMyRequest}
        />
      )}
    </>
  );
}
